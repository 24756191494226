<template>
  <div class="grid-shifted">
    <b-row v-bind="gridColumns">
      <b-col v-for="sKey in contentSlotKeys" :key="sKey">
        <slot :name="sKey"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
const EXCLUDE_SCOPED_SLOTS = []

export default {
  name: 'GridShifted',
  data () {
    return {
      gridColumns: {
        cols: 1,
        [`cols-${this.$root.md.key}`]: 2
      }
    }
  },
  computed: {
    contentSlotKeys () {
      return Object.keys(this.$scopedSlots || {})
        .filter(sKey => !EXCLUDE_SCOPED_SLOTS.includes(sKey))
    }
  }
}
</script>

<style lang="scss">
</style>
