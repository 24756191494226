<template>
  <b-col class="grid-mixed-col" v-bind="colOptions">
    <slot/>
  </b-col>
</template>

<script>
export default {
  name: 'GridMixedCol',
  data () {
    const slotElement = this.$slots.default[0] || {}
    const componentOptions = ((slotElement.componentOptions || {}).Ctor || {}).options || {}

    return {
      isProductTeaser: componentOptions.name === 'ProductTeaser'
    }
  },
  computed: {
    colOptions () {
      if (this.isProductTeaser) {
        return {
          [this.$root.mt.key]: 4,
          [this.$root.md.key]: 3
        }
      }

      return {
        'align-self': 'center'
      }
    }
  },
  created () {
    if (this.isProductTeaser) {
      this.$slots.default.splice(1, this.$slots.default.length)
    }
  }
}
</script>

<style lang="scss">
.grid-mixed-col {}
</style>
