<template>
    <div class="fallback-image">
        <slot v-if="$slots.default"/>
        <aspect-ratio v-else ratio="4x3" class="is-fallback-image">
            <lazy-icon icon="images"/>
        </aspect-ratio>
    </div>
</template>

<script>
import AspectRatio from '@/components/private/AspectRatio'

export default {
  name: 'FallbackImage',
  components: {
    AspectRatio
  }
}
</script>

<style lang="scss">
$fallback-image-fallback-size: 100% !default;
$fallback-image-fallback-color: $imgfallback !default;

.fallback-image {
    img {
        display: block;
        width: 100%;
        max-width: none;
    }

    .is-fallback-image {
        .bi {
            display: block;
            margin: auto;
            width: $fallback-image-fallback-size;
            height: $fallback-image-fallback-size;
            color: $fallback-image-fallback-color;
        }
    }
}
</style>
