<template>
  <div class="service-teaser">
    <slot/>

    <div class="teaser-profiles">
      <b-row align-h="center">
        <b-col v-for="(profile, pIndex) in profiles" :key="pIndex" cols="auto">
          <div class="profile">
            <div class="profile-image">
              <img v-bind="profile.img"/>
            </div>

            <div class="profile-name">{{ profile.name }}</div>
            <div class="profile-text">{{ profile.text }}</div>
          </div>
        </b-col>
      </b-row>
    </div>

    <div class="teaser-action">
      <slot name="action"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServiceTeaser',
  props: {
    profiles: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss">
$serviceteaser-paddings: (
  mobileY: $component-padding-y * 1.5,
  desktopY: $component-desktop-padding-y * 1.5
) !default;

  // mobile
$serviceteaser-profiles-gap: $spacer * 1.5 !default;
$serviceteaser-profiles-color: $brown !default;
$serviceteaser-profiles-text-align: center !default;

$serviceteaser-profile-margin-y: $spacer * 0.5 !default;
$serviceteaser-profile-margin-x: 0 !default;
$serviceteaser-profile-width: 160px !default;

$serviceteaser-profile-image-gap: $paragraph-margin-bottom !default;
$serviceteaser-profile-image-border: set-nth(map-get($borders, 'bright'), 1, 3px) !default;
$serviceteaser-profile-image-border-offset: nth($serviceteaser-profile-image-border, 1) * 1.5 !default;

$serviceteaser-profile-name-font-size: $font-size-base !default;
$serviceteaser-profile-name-font-weight: $font-weight-bold !default;
$serviceteaser-profile-name-letter-spacing: $letter-spacing-1 !default;

$serviceteaser-profile-text-font-size: $serviceteaser-profile-name-font-size !default;
$serviceteaser-profile-text-font-weight: $font-weight-normal !default;
$serviceteaser-profile-text-letter-spacing: $letter-spacing-1 !default;

// desktop
$serviceteaser-desktop: $desktop-breakpoint !default;

$serviceteaser-desktop-profiles-gap: $spacer * 2.5 !default;

$serviceteaser-desktop-profile-margin-y: $spacer !default;
$serviceteaser-desktop-profile-margin-x: $spacer !default;
$serviceteaser-desktop-profile-width: 240px !default;

$serviceteaser-desktop-profile-image-gap: $serviceteaser-profile-image-gap !default;
$serviceteaser-desktop-profile-image-border: $serviceteaser-profile-image-border !default;
$serviceteaser-desktop-profile-image-border-offset: nth($serviceteaser-desktop-profile-image-border, 1) * 2.25 !default;

$serviceteaser-desktop-profile-name-font-size: $font-size-lg !default;
$serviceteaser-desktop-profile-name-font-weight: $serviceteaser-profile-name-font-weight !default;
$serviceteaser-desktop-profile-name-letter-spacing: $serviceteaser-profile-name-letter-spacing !default;

$serviceteaser-desktop-profile-text-font-size: $serviceteaser-desktop-profile-name-font-size !default;
$serviceteaser-desktop-profile-text-font-weight: $serviceteaser-profile-text-font-weight !default;
$serviceteaser-desktop-profile-text-letter-spacing: $serviceteaser-profile-text-letter-spacing !default;

.service-teaser {
  @include component('y', false, $serviceteaser-paddings);
  @include fullwidth-bg();
  background-color: $beige;
  color: $serviceteaser-profiles-color;
  text-align: $serviceteaser-profiles-text-align;

  .teaser-profiles {
    margin-top: $serviceteaser-profiles-gap;
    margin-bottom: $serviceteaser-profiles-gap;

    .profile {
      margin: $serviceteaser-profile-margin-y $serviceteaser-profile-margin-x;
      width: $serviceteaser-profile-width;

      .profile-image {
        margin-bottom: $serviceteaser-profile-image-gap;
        padding: $serviceteaser-profile-image-border-offset;
        border: $serviceteaser-profile-image-border;
        border-radius: 50%;

        img {
          display: block;
          width: 100%;
          max-width: none;
          border-radius: 50%;
        }
      }

      .profile-name {
        font-size: $serviceteaser-profile-name-font-size;
        font-weight: $serviceteaser-profile-name-font-weight;
        letter-spacing: $serviceteaser-profile-name-letter-spacing;
      }

      .profile-text {
        font-size: $serviceteaser-profile-text-font-size;
        font-weight: $serviceteaser-profile-text-font-weight;
        letter-spacing: $serviceteaser-profile-text-letter-spacing;
      }
    }
  }

  .teaser-action {}

  @include media-breakpoint-up($serviceteaser-desktop) {
    .teaser-profiles {
      margin-top: $serviceteaser-desktop-profiles-gap;
      margin-bottom: $serviceteaser-desktop-profiles-gap;

      .profile {
        margin: $serviceteaser-desktop-profile-margin-y $serviceteaser-desktop-profile-margin-x;
        width: $serviceteaser-desktop-profile-width;

        .profile-image {
          margin-bottom: $serviceteaser-desktop-profile-image-gap;
          padding: $serviceteaser-desktop-profile-image-border-offset;
          border: $serviceteaser-desktop-profile-image-border;
          border-radius: 50%;

          img {
            display: block;
            width: 100%;
            max-width: none;
            border-radius: 50%;
          }
        }

        .profile-name {
          font-size: $serviceteaser-desktop-profile-name-font-size;
          font-weight: $serviceteaser-desktop-profile-name-font-weight;
          letter-spacing: $serviceteaser-desktop-profile-name-letter-spacing;
        }

        .profile-text {
          font-size: $serviceteaser-desktop-profile-text-font-size;
          font-weight: $serviceteaser-desktop-profile-text-font-weight;
          letter-spacing: $serviceteaser-desktop-profile-text-letter-spacing;
        }
      }
    }
  }
}
</style>
