<template>
  <div :class="['eyecatcher', { [`eyecatcher-${variant}`]: variant, [`eyecatcher-${size}`]: size }]">
    <svg ref="svg"  >
      <text ref="text" x="50%" y="50%" text-anchor="middle" dominant-baseline="middle" :style="{ fontSize: fontSize+'px'}" alignment-baseline="middle"><slot/></text>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Eyecatcher',
  props: {
    variant: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: 'md'
    }
  },
  data () {
    return {
      wasVisibleOnce: false,
      svgSize: 0,
      fontSize: 16
    }
  },
  methods: {
    updateSvgSize () {
      const newText = this.$refs.text.textContent
      const newLength = newText.length
      const charsPerLine = 4
      const newEmSize = charsPerLine / newLength

      const textBaseSize = 16
      let formattedSize
      let newFontSize

      if (newEmSize < 1) {
        newFontSize = newEmSize * textBaseSize
        formattedSize = newFontSize
      } else {
        formattedSize = textBaseSize
      }

      this.fontSize = formattedSize
    }
  },
  mounted () {
    this.$nextTick(this.updateSvgSize)
    if (!this.wasVisibleOnce) {
      this.$root.$on('bv::collapse::state', () => {
        if (this.$refs.svg) {
          this.$nextTick(this.updateSvgSize)
          this.wasVisibleOnce = this.$el.offsetWidth > 0 && this.$el.offsetHeight > 0
        }
      })
    }
  },
  watch: {
    '$root.mobilefirstBreakpoint' (newBreakpoint, oldBreakpoint) {
      if (this.$refs.svg && newBreakpoint !== oldBreakpoint) this.$nextTick(this.updateSvgSize())
    }
  }
}
</script>

<style lang="scss">
$eyecatcher-size: 60px !default;
$eyecatcher-size-sm: 48px !default;
$eyecatcher-size-lg: 75px !default;
$eyecatcher-box-shadow: map-get($shadows, 'salebutton') !default;

$eyecatcher-svg-font-weight: $font-weight-bold !default;
$eyecatcher-svg-font-size: 100px !default;
$eyecatcher-svg-text-transform: uppercase !default;

.eyecatcher {
  display: inline-block;
  width: $eyecatcher-size;
  height: $eyecatcher-size;
  border-radius: 50%;
  box-shadow: $eyecatcher-box-shadow;
  position:relative;

  svg {
    display: inline-block;
    max-width: 100%;
    max-height:100%;
    font-weight: $eyecatcher-svg-font-weight;
    font-size: $eyecatcher-svg-font-size;
    text-transform: $eyecatcher-svg-text-transform;
    white-space: nowrap;
    line-height: 1;
    fill: currentColor;
  }

  &.eyecatcher-sm {
    width: $eyecatcher-size-sm;
    height: $eyecatcher-size-sm;
  }

  &.eyecatcher-lg {
    width: $eyecatcher-size-lg;
    height: $eyecatcher-size-lg;
  }

  @each $key, $value in $theme-colors {
    &.eyecatcher-#{$key} {
      background-color: $value;
      color: color-yiq($value);
    }
  }
}
</style>
