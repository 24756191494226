<template>
  <div class="grid-mixed">
    <b-row class="grid-mixed-row">
      <slot/>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'GridMixed'
}
</script>

<style lang="scss">
.grid-mixed {
  @include component('y');

  .grid-mixed-row {
    margin-top: $paragraph-margin-bottom * -0.5;
    margin-bottom: $paragraph-margin-bottom * -0.5;

    > .grid-mixed-col {
      margin-top: $paragraph-margin-bottom * 0.5;
      margin-bottom: $paragraph-margin-bottom * 0.5;
    }
  }

  + .grid-mixed {
    .grid-mixed-row {
      margin-top: $paragraph-margin-bottom * -1;
    }
  }
}
</style>
