<template functional>
  <div :id="data.id" :class="['testimonial', data.class, data.staticClass]" :style="data.style" v-bind="data.attrs">
    <div class="testimonial-avatar">
      <b-avatar class="avatar-image" variant="light" :src="props.img"/>
      <div class="avatar-info">
        <div class="avatar-name">{{ props.name }}</div>
        <div class="avatar-text" v-if="props.text">{{ props.text }}</div>
      </div>
    </div>

    <div class="testimonial-content">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Testimonial',
  props: {
    img: {
      type: String,
      default: null
    },
    name: {
      type: String,
      required: true
    },
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
// mobile
$testimonial-padding-y: 0 !default;
$testimonial-padding-x: $testimonial-padding-y !default;

$testimonial-avatar-gap: $paragraph-margin-bottom !default;

$testimonial-avatar-image-size: 72px !default;

$testimonial-avatar-info-gap: $spacer !default;

$testimonial-avatar-name-font-size: $font-size-base !default;
$testimonial-avatar-name-font-weight: $font-weight-bold !default;
$testimonial-avatar-name-color: inherit !default;

$testimonial-avatar-text-font-size: $testimonial-avatar-name-font-size !default;
$testimonial-avatar-text-font-weight: $font-weight-normal !default;
$testimonial-avatar-text-color: $dark !default;

// desktop
$testimonial-desktop: $desktop-breakpoint !default;

$testimonial-desktop-padding-y: $grid-gutter-width !default;
$testimonial-desktop-padding-x: $testimonial-desktop-padding-y !default;

.testimonial {
  padding: $testimonial-padding-y $testimonial-padding-x;

  .testimonial-avatar {
    display: flex;
    align-items: center;
    margin-bottom: $testimonial-avatar-gap;

    .avatar-image {
      width: $testimonial-avatar-image-size;
      height: $testimonial-avatar-image-size;
    }

    .avatar-info {
      margin-left: $testimonial-avatar-info-gap;

      .avatar-name {
        font-size: $testimonial-avatar-name-font-size;
        font-weight: $testimonial-avatar-name-font-weight;
        color: $testimonial-avatar-name-color;
      }

      .avatar-text {
        font-size: $testimonial-avatar-text-font-size;
        font-weight: $testimonial-avatar-text-font-weight;
        color: $testimonial-avatar-text-color;
      }
    }
  }

  .testimonial-content {}

  @include media-breakpoint-up($testimonial-desktop) {
    padding: $testimonial-desktop-padding-y $testimonial-desktop-padding-x;
  }
}
</style>
