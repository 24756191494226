<template>
  <div :class="['inspiration-teaser', `bg-pastel-${variant}`, `img-order-${imgOrder}`, hasAsideImages ? 'extended' : 'narrow']">
    <div class="inspiration-teaser-content">
      <div class="content-images">
        <div class="image-main">
          <aspect-ratio ratio="3x4" :style="`background-image: url(${mainImage.src});`" :enableStyles="onceAboveTheFold">
            <span class="sr-only">{{ mainImage.alt }}</span>
          </aspect-ratio>
        </div>

        <div class="images-aside" v-if="$root.isDesktop && ($slots.handwriting || hasAsideImages)">
          <c-handwriting>
            <slot name="handwriting" />
          </c-handwriting>

          <aspect-ratio
            v-for="(image, iIndex) in asideImages"
            :key="iIndex"
            ratio="4x3"
            :style="`background-image: url(${image.src});`"
            :enableStyles="onceAboveTheFold"
          >
            <span class="sr-only">{{ image.alt }}</span>
          </aspect-ratio>
        </div>
      </div>

      <div class="content-text">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import MixinAboveTheFold from '@/components/mixins/AboveTheFold'
import AspectRatio from '@/components/private/AspectRatio'

export default {
  name: 'InspirationTeaser',
  mixins: [MixinAboveTheFold],
  components: {
    AspectRatio
  },
  props: {
    variant: {
      type: String,
      default: 'beige'
    },
    images: {
      type: Array,
      required: true
    },
    imgLeft: {
      type: Boolean,
      default: false
    },
    imgRight: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    mainImage() {
      return this.images[0] || {}
    },
    asideImages() {
      return (this.images || []).slice(1)
    },
    hasAsideImages() {
      return this.asideImages.length > 0
    },
    imgOrder() {
      return this.imgLeft || !this.imgRight ? 'left' : 'right'
    }
  }
}
</script>

<style lang="scss">
// mobile
$inspirationteaser-padding-y: $grid-gutter-width * 0.5 !default;
$inspirationteaser-padding-x: 0 !default;
$inspirationteaser-pastel-colors: $pastel-colors !default; // use map-merge() if you need to add more custom colors

$inspirationteaser-content-padding-y: $grid-gutter-width !default;
$inspirationteaser-content-padding-x: $grid-gutter-width * 0.5 !default;

$inspirationteaser-content-images-offset: $inspirationteaser-content-padding-y * -2.25 !default;
$inspirationteaser-content-images-box-shadow: map-get($shadows, 'image') !default;

$inspirationteaser-content-images-main-widths: (
  xs: 70%,
  sm: 50%,
  md: 50%
) !default;

$inspirationteaser-content-text-gap: $spacer * 1.5 !default;
$inspirationteaser-content-text-align: center !default;

// desktop
$inspirationteaser-breakpoint: $desktop-breakpoint !default;

// narrow
$inspirationteaser-desktop-narrow-padding-y: $grid-gutter-width !default;
$inspirationteaser-desktop-narrow-padding-x: 0 !default;

$inspirationteaser-desktop-narrow-content-padding-y: $grid-gutter-width * 1.5 !default;
$inspirationteaser-desktop-narrow-content-padding-x: $grid-gutter-width * 2 !default;

$inspirationteaser-desktop-narrow-content-images-gap: $grid-gutter-width * 0.8 !default;
$inspirationteaser-desktop-narrow-content-images-offset: $inspirationteaser-desktop-narrow-content-padding-y * -3 !default;

$inspirationteaser-desktop-narrow-content-images-main-width: 66% !default;

$inspirationteaser-desktop-narrow-content-text-gap: $spacer * 2 !default;
$inspirationteaser-desktop-narrow-content-text-align: left !default;

// extended
$inspirationteaser-desktop-extended-padding-y: $inspirationteaser-desktop-narrow-padding-y * 1.5 !default;
$inspirationteaser-desktop-extended-padding-x: $inspirationteaser-desktop-narrow-padding-x !default;

$inspirationteaser-desktop-extended-content-padding-y: 0 !default;
$inspirationteaser-desktop-extended-content-padding-x: 0 !default;

$inspirationteaser-desktop-extended-content-images-gap: $inspirationteaser-desktop-narrow-content-images-gap !default;
$inspirationteaser-desktop-extended-content-images-offset: 0 !default;
$inspirationteaser-desktop-extended-content-images-padding-y: $grid-gutter-width * 1.5 !default;
$inspirationteaser-desktop-extended-content-images-padding-x: $grid-gutter-width * 3 !default;
$inspirationteaser-desktop-extended-content-images-width: 70% !default;

$inspirationteaser-desktop-extended-content-images-main-width: 60% !default;

$inspirationteaser-desktop-extended-content-text-offset: $inspirationteaser-desktop-extended-content-images-padding-x * -0.5 !default;
$inspirationteaser-desktop-extended-content-text-align: $inspirationteaser-desktop-narrow-content-text-align !default;

.inspiration-teaser {
  padding: $inspirationteaser-padding-y $inspirationteaser-padding-x;

  .inspiration-teaser-content {
    margin-top: abs($inspirationteaser-content-images-offset) - $inspirationteaser-content-padding-y;
    padding: $inspirationteaser-content-padding-y $inspirationteaser-content-padding-x;

    .content-images {
      margin-top: $inspirationteaser-content-images-offset;

      .aspectratio {
        background: no-repeat center center;
        background-size: cover;
        box-shadow: $inspirationteaser-content-images-box-shadow;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .image-main {
        margin: 0 auto;

        @each $key, $value in $inspirationteaser-content-images-main-widths {
          @include media-breakpoint-up($key) {
            width: $value;
          }
        }
      }
    }

    .content-text {
      margin-top: $inspirationteaser-content-text-gap;
      text-align: $inspirationteaser-content-text-align;
    }
  }

  @each $key, $value in $inspirationteaser-pastel-colors {
    &.bg-#{$key} {
      .inspiration-teaser-content {
        background-color: $value;
      }
    }
  }

  @include media-breakpoint-up($inspirationteaser-breakpoint) {
    &.narrow {
      padding: $inspirationteaser-desktop-narrow-padding-y $inspirationteaser-desktop-narrow-padding-x;

      .inspiration-teaser-content {
        margin-top: abs($inspirationteaser-desktop-narrow-content-images-offset) - $inspirationteaser-desktop-narrow-content-padding-y;
        padding: $inspirationteaser-desktop-narrow-content-padding-y $inspirationteaser-desktop-narrow-content-padding-x;

        .content-images {
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;
          margin-top: $inspirationteaser-desktop-narrow-content-images-offset;

          .aspectratio {
            margin-bottom: $inspirationteaser-desktop-narrow-content-images-gap;

            &:last-child {
              margin-bottom: 0;
            }
          }

          .image-main {
            flex: 0 0 $inspirationteaser-desktop-narrow-content-images-main-width;
            margin: 0;
          }

          .images-aside {
            flex: 0 0 (100 - $inspirationteaser-desktop-narrow-content-images-main-width);
            display: block;
            padding-left: $inspirationteaser-desktop-narrow-content-images-gap;

            .handwriting {
              margin-right: $inspirationteaser-desktop-narrow-content-padding-x * -0.5;
            }
          }
        }

        .content-text {
          margin-top: $inspirationteaser-desktop-narrow-content-text-gap;
          text-align: $inspirationteaser-desktop-narrow-content-text-align;
        }
      }
    }

    &.extended {
      padding: $inspirationteaser-desktop-extended-padding-y $inspirationteaser-desktop-extended-padding-x;

      .inspiration-teaser-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: abs($inspirationteaser-desktop-extended-content-images-offset) - $inspirationteaser-desktop-extended-content-padding-y;
        padding: $inspirationteaser-desktop-extended-content-padding-y $inspirationteaser-desktop-extended-content-padding-x;
        background-color: transparent;

        .content-images {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex: 0 0 $inspirationteaser-desktop-extended-content-images-width;
          margin-top: $inspirationteaser-desktop-extended-content-images-offset;
          padding: $inspirationteaser-desktop-extended-content-images-padding-y $inspirationteaser-desktop-extended-content-images-padding-x;

          aspect-ratio: auto 37/27;

          .aspectratio {
            margin-bottom: $inspirationteaser-desktop-extended-content-images-gap;

            &:last-child {
              margin-bottom: 0;
            }
          }

          .image-main {
            flex: 0 0 $inspirationteaser-desktop-extended-content-images-main-width;
          }

          .images-aside {
            flex: 0 0 (100 - $inspirationteaser-desktop-extended-content-images-main-width);
          }
        }

        .content-text {
          flex: 1 0 (100 - $inspirationteaser-desktop-extended-content-images-width);
          position: relative;
          z-index: 2;
          text-align: $inspirationteaser-desktop-extended-content-text-align;
        }
      }

      &.img-order-left {
        .inspiration-teaser-content {
          .content-images {
            .image-main {
              order: 2;
            }

            .images-aside {
              padding-right: $inspirationteaser-desktop-extended-content-images-gap;

              .handwriting {
                margin-left: $inspirationteaser-desktop-extended-content-images-padding-x * -0.5;
                text-align: right;
              }
            }
          }

          .content-text {
            margin-left: $inspirationteaser-desktop-extended-content-text-offset;
          }
        }
      }

      &.img-order-right {
        .inspiration-teaser-content {
          .content-images {
            order: 2;

            .images-aside {
              padding-left: $inspirationteaser-desktop-extended-content-images-gap;

              .handwriting {
                margin-right: $inspirationteaser-desktop-extended-content-images-padding-x * -0.5;
              }
            }
          }

          .content-text {
            margin-right: $inspirationteaser-desktop-extended-content-text-offset;
          }
        }
      }

      @each $key, $value in $inspirationteaser-pastel-colors {
        &.bg-#{$key} {
          .inspiration-teaser-content {
            .content-images {
              background-color: $value;
            }
          }
        }
      }
    }
  }
}
</style>
