<template functional>
  <compontent :is="props.tag" :id="data.id" :class="['fact-item', data.class, data.staticClass, 'font-spacing-1']" :style="data.style" v-bind="data.attrs">
    <c-eyecatcher-icon :iconName="props.iconName" :iconVariant="props.iconVariant" :iconSize="props.iconSize">
      <slot/>
    </c-eyecatcher-icon>
  </compontent>
</template>

<script>
export default {
  name: 'FactItem',
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    iconName: {
      type: String,
      default: null
    },
    iconVariant: {
      type: String,
      default: null
    },
    iconSize: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss">
$factitem-color: $brown !default;

.fact-item {
  color: $factitem-color;
}
</style>
