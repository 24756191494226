<template>
  <div>
    <img v-if="width" v-bind="$attrs" :src="src"/>
  </div>
</template>

<script>
export default {
  name: 'ResponsiveImage',
  inheritAttrs: false,
  data () {
    return {
      width: null
    }
  },
  computed: {
    widthParameter () {
      return this.width !== null ? this.$attrs.src.includes('?') ? `&w=${this.width}` : `?w=${this.width}` : ''
    },
    src () {
      return `${this.$attrs.src}${this.widthParameter}`
    }
  },
  methods: {
    updateWidth () {
      this.width = this.$el.scrollWidth > this.width ? this.$el.scrollWidth : this.width
    }
  },
  created () {
    window.addEventListener('load', this.updateWidth)
  },
  beforeDestroy () {
    window.removeEventListener('load', this.updateWidth)
  },
  watch: {
    '$root.mobilefirstBreakpoint' (newBreakpoint, oldBreakpoint) {
      if (newBreakpoint !== oldBreakpoint) this.updateWidth()
    }
  }
}
</script>
